// Core
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

// Components
import { Text } from "@/client/components/ui/Typography/Text";
import { Button } from "@/client/components/ui/Button";
import { InputForm } from "@/client/components/ui/FormFields/InputForm";
import { Form } from "@/client/components/ui/Form";
import { WrappedFormInput } from "@/client/components/form/wrapped-form-input";
import { WrappedFormPassword } from "@/client/components/form/wrapped-form-input-password";
import { TitleBlock } from "@/client/components/others/title-block";
import { Link } from "@/client/components/others/link";

// Definitions
import type { FormSubmitFn } from "@/client/components/form/form.types";
import type { LoginRequestType } from "../../types";

// Module
import { loginForm, inputConfig, LoginFormEnum } from "./config";
import st from "./styles.module.css";

// Utils
import { getFieldsMetaValidation } from "@/client/utils/forms";
import { routes } from "@/client/definitions/book";

type SigninFormPropsType = {
  loading: boolean;
  testId: string;
  onSubmit: FormSubmitFn<LoginRequestType>;
  onRedirectToSignup: () => void;
};
export const SigninForm = ({
  loading,
  testId,
  onSubmit,
  onRedirectToSignup,
}: SigninFormPropsType) => {
  const { t } = useTranslation(["buttons", "inputs", "signin"]);

  const loginFormProps = useForm<LoginRequestType>({
    defaultValues: { ...loginForm.shape },
    resolver: zodResolver(loginForm.schema(t)),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, setError, reset } = loginFormProps;
  const { isSubmitting } = formState;
  const fieldsMeta = getFieldsMetaValidation(LoginFormEnum, formState);

  const onSubmitForm = () => {
    void (async () => {
      await handleSubmit((values) => {
        onSubmit?.({
          values,
          acts: {
            setError,
            reset,
          },
        });
      })();
    })();
  };

  return (
    <Form onFinish={onSubmitForm} name="signin-form" data-testid={testId}>
      <TitleBlock>{t("signin:form.title")}</TitleBlock>

      <InputForm
        id={LoginFormEnum.login}
        name={LoginFormEnum.login}
        validateStatus={fieldsMeta.login.status}
        {...fieldsMeta.login.helpText}
      >
        <WrappedFormInput
          name={LoginFormEnum.login}
          control={control}
          placeholder={t(inputConfig.login.placeholder)}
          hasDisabled={loading || isSubmitting}
          autoFocus
        />
      </InputForm>

      <InputForm
        id={LoginFormEnum.password}
        name={LoginFormEnum.password}
        validateStatus={fieldsMeta.password.status}
        {...fieldsMeta.password.helpText}
      >
        <WrappedFormPassword
          name={LoginFormEnum.password}
          control={control}
          hasDisabled={loading || isSubmitting}
          placeholder={t(inputConfig.password.placeholder)}
        />
      </InputForm>

      <div className={st["signin-form__forgot-password"]}>
        <Link testId="signin-forgot-pwd" href={{ pathname: routes.signin }}>
          {t("signin:form.forgotPass")}
        </Link>
      </div>

      <div className={st.actions}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          block
          loading={loading || isSubmitting}
          disabled={loading || isSubmitting}
          data-testid="signin-btn-access"
        >
          <Text size="16" fontWeight="600">
            {t("buttons:signIn")}
          </Text>
        </Button>
        <Button
          href={routes.signup}
          type="link"
          size="large"
          block
          mode="custom"
          onClick={onRedirectToSignup}
          data-testid="signin-btn-reject"
        >
          <Text size="16" fontWeight="600">
            {t("buttons:registration")}
          </Text>
        </Button>
      </div>
    </Form>
  );
};
